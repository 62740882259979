<template>
  <div>
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container">
          <h3>
            Our Commitment to Transparency, Fraud Prevention, and Excellence
          </h3>
          <h4>
            Fundraiser Rights and Responsibilities for Credit Card Transactions
          </h4>
          <h5>Common types of debit and credit card fraud:</h5>
          <ul>
            <li>
              Card duplication which allows for most types of unauthorized
              purchases.
            </li>
            <li>
              Unauthorized purchases over the internet, phone or mail resulting
              from a lost or stolen credit card.
            </li>
            <li>
              Identity theft which can often result in new credit accounts
              opened fraudulently.
            </li>
          </ul>
          <p>
            M-Changa policies for fraudulent Credit Card transactions /
            donations Accepting donations via credit card requires Verification
            of you or your organization as the ultimate recipient of the funds
            in the account.
          </p>
          <p>
            Our policy regarding chargebacks is zero tolerance. If we receive a
            chargeback notice from you or your credit card company, your
            services with us will be suspended immediately along with any
            accrued balances from prior or ongoing fundraising activity pending
            investigation.
          </p>
          <p>
            In order to reinstate your services and access any accrued balances,
            you will need to pay the balance due on your account in addition to
            a $50 chargeback fee. Balance due includes the transactions charged
            back in addition to any future chargebacks.
          </p>

          <h5>M-Changa security experts are here to help</h5>
          <p>
            <strong>30 point Transaction Verification - </strong> M-Changa
            strives to ensure that donations made to your campaign are
            legitimate. We look at 30 key authentication verifiers with each
            credit card donation made to our platform.
          </p>
          <p>
            <strong>Fundraiser Fraud Early Warning — </strong>If there's any
            unusual credit card donation activity in your fundraiser, such as
            country of origin being from a 'flagged' country, or an unusually
            large donation, we'll contact you and the donor to verify the
            donation.
          </p>
          <p>
            <strong>Fundraiser Transaction Security — </strong>Our specialists
            are available by phone to address any unusual activity you may
            notice on your fundraiser. Call +254 20 765 0919.
          </p>
          <h5>Your responsibilities for Credit Card Transactions</h5>
          <p>
            M-Changa may provide or suggest security procedures and controls
            intended to reduce the risk to you of fraud (“Security Controls”).
            These Security Controls may include processes or applications that
            are developed by M-Changa and/or by third parties.
          </p>
          <p>
            Disabling or failing to properly use Security Controls increases
            your exposure to unauthorized Card transactions. Keep in mind that
            you are responsible for the use of lost or stolen data that is used
            to donate to your fundraiser. M-Changa will not be liable for losses
            resulting from use of lost or stolen data with the Service
          </p>
          <p>
            Acting fast limits your liability for fraudulent donations to your
            fundraiser. Report any suspicious donation activity immediately If
            you receive communication from a suspicious donor, let us know
            immediately. If you receive a donation from an individual or
            organization whom you do not know, or are unable to communicate
            with, let us know immediately
          </p>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import Footer from "../components/Footer/index.vue";
export default {
  components: {
    Breadcrumbs,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
